import { COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import lodash from 'lodash';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';
import memoizedAccessChecker from '../../../../utilities/accessChecker';
import { checkAccessForEachCriteria, getCriteriaKeys } from '../../../../utilities/inventoryAccess';

const { isEqual } = lodash;

const _p = { memoizedAccessChecker, checkAccessForEachCriteria, getCriteriaKeys };
export const _private = _p;

export default {
    verb: 'doingValidate',
    namespace: 'inventory',
    relation: 'inventory',
    priority: 10,
    featureFlag: 'multiInventory',
    description:
        'Warn the user when removing a location from an inventory or when changing the operator of a criteria entry',
    prerequisites: [
        {
            context: { verb: 'get', namespace: 'identity', relation: 'userRole' }
        }
    ],
    logic: doingValidate
};

async function doingValidate({ data, context, prerequisiteResults, dispatch }) {
    const { oldRecord, newRecord } = data;
    const { namespace, relation } = context;
    const userRole = prerequisiteResults?.[0]?.result?.[0];
    const clientDataRights = getAllFeatureFlags(context).includes('clientDataRights') ?? false;
    const dataAccess = await _p.memoizedAccessChecker(namespace, relation, userRole, clientDataRights);
    // Check access for ALL location criteria before allowing any modifications
    const locationCriteria = _p.getCriteriaKeys();
    for (const criteria of locationCriteria) {
        await _p.checkAccessForEachCriteria(oldRecord, criteria, dataAccess);
        // checking access for the new criteria is more of a safety net
        // since the drop down will only show criteria that the user has access to
        await _p.checkAccessForEachCriteria(newRecord, criteria, dataAccess);
    }

    //if nothing was found yet, don't bother the user.
    if (!oldRecord?.foundAssets) return data;
    //if we are just creating a brand new inventory, no problem
    if (!oldRecord.title) return data;

    //create a helper to present popup to user:
    const presentChoiceToUser = usersChoice(data, dispatch);

    if ((!oldRecord.criteria || !oldRecord.criteria.length) && newRecord.criteria?.length) {
        return presentChoiceToUser(
            'Adding criteria to this inventory could result in previously included assets now getting excluded.'
        );
    }
    for (const criteria of locationCriteria) {
        const oldCriteria = (oldRecord.criteria?.find(c => c[criteria]) || {})[criteria];
        const newCriteria = (newRecord.criteria?.find(c => c[criteria]) || {})[criteria];
        //not changing criteria is no problem, but check the others
        if (isEqual(oldCriteria, newCriteria)) continue;

        const [oldOp] = Object.keys(oldCriteria);
        const [newOp] = Object.keys(newCriteria);
        if (oldOp != newOp) {
            return presentChoiceToUser(
                'Changing the criteria operation will result in previously included assets now getting excluded.'
            );
        }

        const oldValues = oldCriteria[oldOp];
        const newValues = newCriteria[newOp];

        //There WAS no criteria, but now there is going to be? That means we are excluding assets
        if (!Object.keys(oldValues).length && Object.keys(newValues).length) {
            return presentChoiceToUser([
                'Adding criteria to this inventory could result in previously included assets now getting excluded.'
            ]);
        }

        const newlyRemoved = oldValues.filter(ov => !newValues.some(pv => pv._id === ov._id));

        if (newlyRemoved.length) {
            return presentChoiceToUser(
                `Removing ${newlyRemoved
                    .map(v => v.title)
                    .join(', ')} could result in previously included assets now getting excluded.`
            );
        }
        //adding additional criteria (beyond the first) only adds items, it never would exclude previously included assets
    }
    return data;
}

function usersChoice(unmodifiedData, dispatch) {
    return async function presentChoiceToUser(message) {
        //ALERT user
        const userOK = await new Promise(resolve => {
            dispatch(
                {
                    // title: ' ☠ ☠ WARNING! ☠ ☠ DEATH MAY COME YOUR WAY ☠ ☠ ',
                    message: [
                        message,
                        'If any of those assets were already found, that information will be irretrievably lost.',
                        'Continue?'
                    ],
                    okButtonText: 'YES',
                    cancelButtonText: 'NO',
                    icon: 'warning',
                    iconColor: COMMON_COLOR_SCHEME.warn,
                    okAction: () => resolve(true),
                    cancelAction: () => resolve(false)
                },
                { verb: 'confirm', namespace: 'application', relation: 'user' }
            );
        });
        if (!userOK) {
            //no need to further process the record with this change
            throw new Error('User cancelled changing inventory criteria.');
        }
        return unmodifiedData;
    };
}
