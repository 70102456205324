import { memo, createElement as rc, useEffect, useState, useCallback } from 'react';
import { useTheme } from 'styled-components';
import BarcodeScanner from '../actionElement/BarcodeScanner';
import SensorReadButton from '../actionElement/SensorReadButton';
import _FormField from '../formElement/FormField';
import { fromTheme, styled, View, TextInput, Button } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import useEventSink from '../../hooks/useEventSink';
import useReads from '../../hooks/useReads';
import useTransforms from '../transforms/useTransforms';
import propTypes from 'prop-types';
import PowerSliderPopup from '../actionElement/PowerSliderPopup';
import useReadButtonSelection from '../../hooks/useReadButtonSelection';

const RECORD_SEPARATOR = String.fromCharCode(30);

const Container = styled(View).attrs({ name: 'container' })`
    flex-direction: row;
    max-width: ${fromTheme('width')};
    ${({ theme }) => {
        if (theme.mobile) {
            return `
                align-items: flex-end;
                min-height: 45px;
            `;
        }
    }}
`;
Container.displayName = 'UserActivatedInputContainer';

const CheckButton = styled(Button).attrs(({ theme }) => ({disabledColor: theme.lighterDisabledFontColor}))``;
CheckButton.displayName = 'CheckButton';

export const FormField = styled(_FormField).attrs({ name: 'find-record-form-field' })`
    margin: 0;
    margin-left: ${fromTheme('viewMargin')};
    min-width: 200px;
    flex-grow: 1;
`;
FormField.displayName = 'FormField';

export const RfidButton = styled(SensorReadButton).attrs({
    name: 'rfid-button',
    alt: 'Scan for RFID',
    color: 'transparent'
})``;
RfidButton.displayName = 'RfidButton';

// min-height here is important as the native app wants to squish the text entry area of the input
export const FindTextInput = styled(TextInput)`
    flex-grow: 1;
    min-height: ${({ theme }) => theme.fontSize + theme.textMargin * 2 + theme.textPadding * 2 + 'px'};
`;
FindTextInput.displayName = 'FindTextInput';

const _p = {
    RfidButton,
    useReads,
    WAIT_BEFORE_CLEAR: 500,
    dispatchRead
};
export const _private = _p;
/**
 * Enables various ways of obtaining a values from the physical world into the system
 * RFID, Barcode (via the camera), human input.
 * Used e.g. on the Take and CICO screens.
 * Uses external logic for actual selecting, updating or creating the record.
 * @param {*} props
 * @returns
 */
const UserActivatedInput = memo(props => {
    const [, publish] = useEventSink();
    const [active, setActive] = useState(false);
    const {
        id: _id,
        disabled,
        currentRoute,
        hNode,
        hNode: { title, displayScanButton = false },
        currentValue,
        onSubmit,
        onChange,
        inputRef,
        inputFocused: _inputFocused,
        inputBlurred: _inputBlurred,
        tooltip,
        placeholder: _placeholder,
        autoFocus = true,
        excludeCheckButton = false,
        labelProps,
        className,
        style,
        errors,
        name,
        sequence: _sequence,
        displayThenClearScannedValue = true,
        ...otherProps
    } = props;

    const id = _id ?? hNode.id;
    const sequence = _sequence ?? hNode?.treePosition?.sequence;
    const placeholder = active || title == null || title === '' ? _placeholder : '';
    const fieldEmpty = currentValue === '';
    const { smallMobile } = useTheme();
    const { transformOnChange } = useTransforms(hNode);

    /**
     * handles enter key press
     * @param {import('react').KeyboardEvent<HTMLInputElement>} event
     */
    const inputKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (currentValue != null && currentValue !== '') {
                dispatchRead(publish, currentValue);
            }
        }
    };

    const { mostRecentIndividualRead } = _p.useReads();

    useEffect(() => {
        if (mostRecentIndividualRead != null) {
            const { asciiTagId, sensorType } = mostRecentIndividualRead;
            // By default, change text (even though it isn't necessary) so that user
            // sees the scan.  This is to reassure a user when a scan triggers some
            // other event like the creation or edit of a record.  In those cases,
            // the input will typically be cleared after the scan to allow the
            // next scan.
            displayThenClearScannedValue && onChange(transformOnChange(asciiTagId), sensorType);
            setTimeout(() => {
                displayThenClearScannedValue && onChange('');
                onSubmit?.(asciiTagId, sensorType);
            }, _p.WAIT_BEFORE_CLEAR);
            if (!displayThenClearScannedValue && onSubmit == null) {
                onChange(transformOnChange(asciiTagId), sensorType);
            }
        }
    }, [mostRecentIndividualRead, transformOnChange, onChange, onSubmit, displayThenClearScannedValue]);

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    const inputChanged = value => {
        let scanType = constants.sensorTypes.MANUAL;
        if (value.includes(RECORD_SEPARATOR)) {
            value = value.replace(RECORD_SEPARATOR, '').replace('\n', '');
            scanType = constants.sensorTypes.BARCODE;
            // dispatch virtual read. useEffect above (using mostRecentIndividualRead)
            //will do the rest of the handling for us
            setTimeout(() => dispatchRead(publish, transformOnChange(value), scanType), 500);
        } else {
            onChange(transformOnChange(value), scanType);
        }
    };

    const inputFocused = useCallback(
        e => {
            setActive(true);
            e.persist();
            _inputFocused?.(e);
        },
        [_inputFocused]
    );

    const inputBlurred = useCallback(
        e => {
            setActive(false);
            e.persist();
            _inputBlurred?.(e);
        },
        [_inputBlurred]
    );

    /**
     * Handler for the onmousedown event.
     * @param {import('react').MouseEvent} event
     */
    const onMouseDown = event => {
        // We want to prevent the default here because by default this will fire an on blur event and will not complete
        // the onclick event.  By preventing the default behavior, we are ensuring that the onblur gets fired after the
        // onclick event completes
        event.preventDefault();
    };

    /**
     * handles mark click action
     * @param {import('react').MouseEvent<HTMLButtonElement>} event
     */
    const onSubmitClick = () => {
        dispatchRead(publish, currentValue);
    };

    // Calculate if we should display these buttons
    const { displayRfidButton, displayBarcodeButton, displayRfidPowerButton } = useReadButtonSelection(hNode);

    // Ensure the label htmlFor is populated with the correct id to match the input
    if (labelProps != null) {
        labelProps.htmlFor = id ? 'formField' + id : labelProps.htmlFor;
    }

    // prettier-ignore
    return rc(Container, { className, style },
        // eslint-disable-next-line no-undef
        displayRfidPowerButton && rc(PowerSliderPopup, { id, currentRoute, displayScanButton }),
        // These COULD be rendered by FormField (below) instead, but these versions are slightly larger and work
        // better for the context UserActivatedInput is used in (mainly for lookups above lists of results).
        // Also, if passed sensorTypes, FormField renders a ReadProvider which will isolate the read results to
        // children components.
        displayRfidButton && rc(_p.RfidButton, {
            id: 'rfidScanner' + id,
            'data-testid': 'rfidScannerButton' + id,
            disabled,
            currentRoute,
            tabIndex: -1
        }),
        displayBarcodeButton && rc(BarcodeScanner, {
            id: 'scanner' + id,
            disabled,
            tabIndex: -1
        }),
        rc(FormField, {
            id: 'formField' + id,
            title,
            active,
            fieldEmpty,
            disabled,
            tooltip,
            labelProps,
            errors
        },
            rc(FindTextInput, {
                id: 'formField' + id,
                'data-testid': 'formField' + id,
                type: 'text',
                className: 'mdl-textfield__input',
                disabled,
                value: currentValue,
                autoComplete: 'off',
                onKeyDown: inputKeyDown,
                onChange: inputChanged,
                onFocus: inputFocused,
                onBlur: inputBlurred,
                maxLength: 50,
                ref: inputRef,
                autoFocus,
                placeholder,
                name: name ?? title,
                sequence,
                ...otherProps
            })
        ),
        !smallMobile && !excludeCheckButton && rc(CheckButton, {
            id: 'checkbutton' + id,
            'data-testid': 'checkbutton' + id,
            icon: 'check',
            color: 'transparent',
            onClick: onSubmitClick,
            onMouseDown,
            disabled: disabled || !currentValue
        })
    );
});
UserActivatedInput.propTypes = {
    hNode: propTypes.shape({
        treePosition: propTypes.shape({
            sequence: propTypes.number.isRequired
        }).isRequired
    })
};
UserActivatedInput.displayName = 'UserActivatedInput';
export default UserActivatedInput;

/**
 *
 * @param {object} props
 * @param {function} props.publish
 * @param {'sensor'} [props.namespace]
 * @param {'read'} [props.relation]
 * @param {string} props.mergeNamespace
 * @param {string} props.mergeRelation
 * @param {string} props.asciiTagId
 * @param {bool} props.displayInAscii
 * @returns
 */
function dispatchRead(publish, asciiTagId, sensorType = constants.sensorTypes.MANUAL) {
    let tag = [
        {
            _id: asciiTagId,
            asciiTagId,
            name: 'Manual',
            time: new Date(),
            sensorType
        }
    ];
    publish(tag, { verb: 'change', namespace: 'sensor', relation: 'read' });
}
