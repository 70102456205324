//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _locate from './locate';
import _read from './read';
import * as _registeredServiceQueues from './registeredServiceQueues';
import _service from './service';
import _tag from './tag';
export const locate = _locate;
export const read = _read;
export const registeredServiceQueues = _registeredServiceQueues;
export const service = _service;
export const tag = _tag;
export default {locate, read, registeredServiceQueues, service, tag};

