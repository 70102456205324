import { constants, ObjectId } from 'lib_ui-services';

export default {
    verb: 'willNew',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Initialize the deployment with g0, g1 and the last modified release',
    useCaseIds: [constants.useCaseIds.ONE_TOUCH],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'deploy',
                relation: 'release',
                type: 'find'
            }
        }
    ],
    //this is the actual logic:
    logic: willNew
};

function willNew({ data, prerequisiteResults }) {
    // find the result with the last modified release
    const releases = prerequisiteResults[0].result.sort(
        (a, b) => new Date(b.meta.serverModifiedTime) - new Date(a.meta.serverModifiedTime)
    );
    const { _id, title } = releases?.[0] || {};
    data.newRecord = {
        ...data.newRecord,
        title: `${title?.substring(0, 10) || ''} - ${new Date().toLocaleString()}`,
        releaseGroups: [
            {
                'deploy:group': {
                    _id: ObjectId('605506fd1637f11038e1963c'),
                    title: 'g1'
                },
                'deploy:release': { _id, title }
            },
            {
                'deploy:group': {
                    _id: ObjectId('605506e21637f11038e1963b'),
                    title: 'g0'
                },
                'deploy:release': { _id, title }
            }
        ]
    };

    return data;
}
