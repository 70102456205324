import { createElement as rc, useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DropdownList as _DropdownList, ListItem, Menu, RowDetailStyle } from './styles';
import { nativeOnlyProperties, webOnlyProperties } from 'lib_ui-services';
const noop = () => {};
function DropDownList(props) {
    const {
        displayUnassignedRow,
        alwaysDisplayUnassignedRow,
        getItemProps,
        menuProps,
        highlightedIndex,
        hNode,
        id,
        inputIsDirty,
        isOpen,
        listItemToString,
        records: _records,
        recordCount: _recordCount,
        selectedValues,
        unassignedValue,
        emptyListValue,
        value,
        closeMenu,
        setHighlightedIndex,
        onBlur: _onBlur,
        onFocus
    } = props || {};

    const { mobile, listLineHeight } = useContext(ThemeContext);
    const RowDetail = useCallback(
        ({ item, theme }) => {
            let displayText = listItemToString(item);
            let disabled = false;
            if (selectedValues?.some(value => value._id === item._id)) {
                displayText = '✓ ' + displayText;
                disabled = true;
            }
            return rc(RowDetailStyle, { disabled, theme, title: displayText }, displayText);
        },
        [listItemToString, selectedValues]
    );

    const Row = useCallback(
        props => {
            const { children, rowIndex, ...otherProps } = props;
            const onFocus = () => {
                setHighlightedIndex(rowIndex);
            };
            if (selectedValues?.some(value => value._id === otherProps['data-id'])) {
                return rc(ListItem, { ...otherProps, onClick: noop, onFocus, rowIndex }, children);
            }
            return rc(ListItem, { ...otherProps, onFocus, rowIndex }, children);
        },
        [setHighlightedIndex, selectedValues]
    );

    let recordCount;
    let records;
    // Add in the "Unassigned Row" if appropriate
    // Either using `alwaysDisplayUnassignedRow` to always display it (e.g. in a multi picker, for data rights)
    // of using `displayUnassignedRow` in a regular dropdown to allow clearing the selection.
    if (
        alwaysDisplayUnassignedRow ||
        (displayUnassignedRow && value != null && !value.isDefaultRecord && !inputIsDirty)
    ) {
        records = [unassignedValue, ..._records];
        recordCount = _recordCount + 1;
    } else if (emptyListValue && !_records?.length > 0) {
        records = [emptyListValue];
        recordCount = 1;
    } else {
        records = _records;
        recordCount = _recordCount;
    }

    // Allow the user to select an item from the list by clicking 'Enter' while it is highlighted
    const _getItemProps = useCallback(
        (...args) => {
            const itemProps = {
                ...getItemProps(...args)
            };
            const onKeyDown = e => {
                if (e.key === 'Enter') {
                    itemProps.onClick(e);
                }
            };
            itemProps.onKeyDown = onKeyDown;
            return itemProps;
        },
        [getItemProps]
    );

    // Close the list when the user goes to a control the dropdown does not contain
    const onBlur = useCallback(
        e => {
            if (e.relatedTarget == null || !e.currentTarget.contains(e.relatedTarget)) {
                closeMenu?.();
                _onBlur?.(e);
            }
        },
        [closeMenu, _onBlur]
    );

    const testId = (hNode.testId ?? hNode.id) + '-menu';
    // prettier-ignore
    return rc(Menu, {
        itemCount: recordCount,
        mobile,
        visible: isOpen,
        ...webOnlyProperties({
            'data-testid': testId,
            accessibility: true,
            accessibilityLabel: testId
        }),
        ...nativeOnlyProperties({
            testId
        }),
        ...menuProps,
        onBlur,
        onFocus
    },
        rc(_DropdownList, {
            id,
            itemCount: recordCount,
            data: records,
            Row,
            RowDetail,
            highlightedIndex,
            selectedItem: value,
            getItemProps: _getItemProps,
            itemHeightPixels: listLineHeight,
            visible: isOpen
        }
        )
    );
}

DropDownList.displayName = 'DropDownList';
export default DropDownList;
