/**
 * Gets all criteria keys from a record's criteria array
 * @param {Object} record - The record containing the criteria array
 * @param {Array<{[key: string]: {[operation: string]: Array<string>}}>} record.criteria - Array of criteria objects.
 *        Each object has a single key (e.g. 'location:company') mapping to an object with an operation key
 *        (e.g. '_in', '_nin') that maps to an array of values.
 *        Example: [{ 'location:company': { '_in': ['companyA', 'companyB'] } }]
 * @returns {Array<string>} Array of criteria keys (e.g., ['location:company', 'location:building', 'location:location'])
 */
export function getCriteriaKeys(record) {
    const defaultLocationKeys = ['location:company', 'location:building', 'location:location'];
    if (!record) return defaultLocationKeys;
    return (record.criteria || []).map(criterion => Object.keys(criterion)[0]).filter(Boolean);
}

/**
 * Checks that the user has permission to change the inventory for each of the locations.
 * @param {Object} record - The original record containing criteria.
 * @param {string} criterionKey - The specific criterion key to check access for (e.g., 'location:company', 'location:building', 'location:location')
 * @param {{hasAccess: (record: object) => boolean}} dataAccess - An object containing the `hasAccess` function.
 *        See {@link ./accessChecker} for implementation details.
 * @param {Function} [handleError] - Optional error handler function that takes an error message. If not provided, throws a default error.
 */
export async function checkAccessForEachCriteria(record, criterionKey, dataAccess, handleError) {
    const oldCriteria = (record.criteria?.find(c => c[criterionKey]) || {})[criterionKey];
    if (!oldCriteria) return;

    const [oldOp] = Object.keys(oldCriteria);
    const oldValues = oldCriteria[oldOp];
    if (!oldValues?.length) return;

    // We need to check that the user has permission to change the inventory for each of the criteria
    // If we pass in the whole record, and the user has access to one location, hasAccess will return true
    // and the user will be able to change the inventory, even if they don't have access to the other locations
    // So we need to explicitly disallow records that have any locations that the user does not have access to
    const criteriaObjects = createCriteriaForAccess(record, oldCriteria, oldValues, oldOp);
    criteriaObjects.forEach(criteriaObject => {
        if (!dataAccess.hasAccess(criteriaObject)) {
            const errorMessage = 'You do not have permission to change this inventory';
            if (handleError) {
                handleError(errorMessage);
            } else {
                throw new Error(errorMessage);
            }
        }
    });
}

/**
 * Creates a new criteria object for access checking by replacing the original criteria object in the record.
 *
 * @param {Object} record - The original record containing criteria.
 * @param {Array<{[key: string]: {[operation: string]: Array<string>}}>} record.criteria - Array of criteria objects.
 *        Each object has a single key (e.g. 'location:company') mapping to an object with an operation key
 *        (e.g. '_in', '_nin') that maps to an array of values.
 *        Example: [{ 'location:company': { '_in': ['companyA', 'companyB'] } }]
 * @param {Object} criteriaObj - The specific criteria object to be replaced.
 * @param {Array} criteriaValues - An array of values to map into new criteria objects.
 * @param {string} criteriaOperation - The operation (e.g., '_in', '_nin') to apply to the values.
 *
 * @returns {Array} A new array of record objects, each with modified criteria for access checking.
 */
export function createCriteriaForAccess(record, criteriaObj, criteriaValues, criteriaOperation) {
    return criteriaValues.map(value => ({
        ...record,
        criteria: record.criteria.map(criterion => {
            // If this contains the criteria object, replace it so that we can check access for each field individually
            if (Object.values(criterion)[0] === criteriaObj) {
                const field = Object.keys(criterion)[0];
                return {
                    [field]: {
                        [criteriaOperation]: [value]
                    }
                };
            }
            // Otherwise return as is
            return criterion;
        })
    }));
}
