export default Object.freeze({
    sst: {
        contactUrl: 'https://www.sstid.com/contact-us'
    },
    redbeam: {
        knowledgeBase: 'https://support.redbeam.com/knowledge',
        contactUrl: 'https://redbeam.com/contact-redbeam',
        tryItNowUrl: 'https://redbeam.com/try-it-now'
    }
});
