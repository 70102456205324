import { sessionStorage } from 'lib_ui-services';

export default {
    verb: 'willRemove',
    namespace: 'security',
    relation: 'profile',
    description: 'Not necessary with the SwitchToAuth0 code, so probably can be removed after that is finished',
    priority: 0,
    logic
};

async function logic() {
    sessionStorage.clear();
}
