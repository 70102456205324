/**
 * Handles session storage operations and listens for session changes.
 *
 * @module sessionStorage
 */

import { onSessionChange } from './session';
import logging from '@sstdev/lib_logging';

/** @private */
let storage;

/**
 * Initializes the storage object when the session is destroyed.
 *
 * @callback onSessionChange
 * @param {string} type - The type of session change event.
 */
onSessionChange(type => {
    if (type === 'destroySession') {
        storage = null;
        logging.debug('[SESSION STORAGE] Session destroyed.');
    }
});

export function getSessionId() {
    verifyStorage();
    return storage?.id;
}
/**
 * Retrieves a value from the session storage.
 *
 * @function getKey
 * @param {string} key - The key of the value to retrieve.
 * @param {*} defaultValue - The default value to return if the key does not exist.
 * @returns {*} The value associated with the key, or the default value if the key does not exist.
 */
export function getKey(key, defaultValue) {
    verifyStorage();
    return storage[key] ?? defaultValue;
}

/**
 * Sets a value in the session storage.
 *
 * @function setKey
 * @param {string} key - The key of the value to set.
 * @param {*} value - The value to set.
 */
export function setKey(key, value) {
    verifyStorage();
    storage[key] = value;
}

/**
 * Deletes a value from the session storage.
 *
 * @function deleteKey
 * @param {string} key - The key of the value to delete.
 */
export function deleteKey(key) {
    verifyStorage();
    delete storage[key];
}

function verifyStorage() {
    if (storage == null) {
        const _sessionId = new Date().getTime();
        logging.debug(`[SESSION STORAGE] Session created: ${_sessionId}`);
        storage = { _sessionId };
    }
}

/**
 * Private storage object for session data.
 *
 * @private
 * @type {Object}
 */
export const _private = { storage };

export function clear() {
    storage = null;
}
