import { errors as _errors, COMMON_COLOR_SCHEME } from 'lib_ui-primitives';
import { constants, globalConfig } from 'lib_ui-services';
const { useCaseIds } = constants;
import lodash from 'lodash';
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
const { cloneDeep } = lodash;

const _p = {
    globalConfig,
    setDefaultValuesIfNecessary
};
export const _private = _p;
export default {
    verb: 'doingValidate',
    namespace: 'deploy',
    relation: 'deployment',
    description: 'Ensure the given record has valid data',
    useCaseIds: [useCaseIds.ONE_TOUCH],
    //this is the actual logic:
    logic: validation
};

async function validation({ data, context, dispatch }) {
    const { newRecord: _newRecord } = data;
    const newRecord = cloneDeep(_newRecord);

    if (['new'].includes(newRecord.status) && !context.isNew) {
        throw new _errors.ValidationError(
            'You cannot update a deployment that is new.  Wait for it to reach a "Ready For Confirmation" status.',
            {}
        );
    }
    if (['failureNoRollback'].includes(newRecord.status)) {
        throw new _errors.ValidationError(
            'You cannot update a deployment that has failed.  Create a new deployment or copy this one.',
            {}
        );
    }

    // Need to merge in defaults to avoid validation errors for missing required values
    // that are defaulted.
    await _p.setDefaultValuesIfNecessary(context, newRecord);
    // Require database password if deploying to a different environment.
    if (!isMinikube(newRecord) && (!isDeployingToCurrentEnvironment(newRecord) || isAnyPasswordIncluded(newRecord))) {
        if (
            typeof newRecord.databasePasswordConfirmation === 'undefined' &&
            newRecord.status === 'Ready For Confirmation'
        ) {
            throw new _errors.ValidationError(
                'If you are deploying to an environment other than the current environment or you are setting a password, you must include the database password with the deployment.',
                {}
            );
        }
        if (typeof newRecord.databasePassword === 'undefined' && newRecord.status === 'new') {
            throw new _errors.ValidationError(
                'If you are deploying to an environment other than the current environment or you are setting a password, you must include the database password with the deployment.',
                {}
            );
        }
    }
    // HTTPRoute deployment specific
    if (newRecord['deploy:type'].title === 'HTTPRoute' && newRecord.status === 'new') {
        // No dry run is performed for the 'green' hostname routes. Get user to confirm.
        const userConfirmed = await confirm(newRecord, dispatch);
        if (!userConfirmed) {
            throw new _errors.ValidationError('HTTPRoute deployment was aborted.', {});
        }
    }
}

function isAnyPasswordIncluded(newRecord) {
    return newRecord.databasePassword || newRecord.rabbitmqErlangCookie || newRecord.rabbitmqPassword;
}

function isMinikube(newRecord) {
    const environmentTitle = newRecord['deploy:environment'].title;
    return environmentTitle === 'minikube';
}
function isDeployingToCurrentEnvironment(newRecord) {
    const environmentTitle = newRecord['deploy:environment'].title;
    const { hostname } = _p.globalConfig();
    const subdomain = hostname.split('.')[0];
    return environmentTitle === subdomain;
}

async function confirm(data, dispatch) {
    // making the assumption that our environment naming convention is <env>-<cluster_suffix> (e.g. dev, dev-v2)
    const environment = data['deploy:environment'].title;
    // don't grab this from the globalConfig - we want the hostname for the environment that the user is deploying to
    const hostname = environment.split('-')[0];
    // also making the assumption that our 'green' hostname is <hostname>-green.sstid.com
    const url = `https://${hostname}-green.sstid.com`;
    return new Promise(resolve => {
        const message = [
            'No dry run is performed for the green hostname routes.',
            `This action will point ${url} to the ${environment} environment.`,
            'Continue?'
        ];
        dispatch(
            {
                message,
                okButtonText: 'YES',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
