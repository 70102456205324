import { authentication } from 'lib_ui-services';

export default {
    verb: 'doingGet',
    namespace: 'application',
    relation: 'session',
    description: 'get the current session',
    priority: 10,
    logic
};

async function logic() {
    const oldSession = await authentication.getCurrentSession();
    return { result: [oldSession] };
}
