import lodash from 'lodash';
const { get, uniq, set, omit, startCase } = lodash;
import { getCurrentSession } from '../authentication/cache';
import useCaseIds from '../constants/useCaseIds';
import localStorage from '../localStorage';
import constants from '../constants';
import _getRouter from '../getRouter';

const titleAlternatives = {
    item: { item: { propertyName: 'assetNo', pretty: 'Asset ID' } },
    identity: {
        user: { propertyName: 'displayName', pretty: 'Display Name' },
        backboneUser: { propertyName: 'userName', pretty: 'Email' }
    },
    rex: { rma: { propertyName: 'rmaNo', pretty: 'RMA No' } }
};

const duplicateCheckFields = {
    application: { savedFilter: ['title', 'identity:user._id', 'routePath'], sharedFilter: ['title', 'routePath'] },
    item: { item: 'assetNo', locationHistory: ['item:item._id', 'location:location._id', 'readTimestamp'] },
    location: {
        location: ['title', 'location:building._id'],
        building: ['title', 'location:company._id']
    },
    identity: {
        user: 'displayName',
        backboneUser: 'userName'
    },
    rex: { rma: 'rmaNo' },
    metadata: {
        page: '_id',
        navigation: 'metadata:useCaseDetail._id',
        profileMenu: 'metadata:useCaseDetail._id',
        namespace: ['title', 'metadata:useCaseDetail._id']
    },
    metaui: {
        captionOverrides: [
            'customDate1',
            'customDate2',
            'customField1',
            'customField2',
            'customField3',
            'customField4',
            'customField5',
            'dropDown1',
            'dropDown2',
            'dropDown3',
            'dropDown4',
            'dropDown5',
            'toggle1',
            'toggle2'
        ]
    }
};

const copyKeysToOmit = {
    item: {
        item: [
            'inventory',
            'serialNo',
            'tagId',
            'checkOut',
            'locationModifiedTime',
            'depreciatedValue',
            'amountDepreciated',
            'logs'
        ]
    },
    deploy: {
        deployment: [
            'status',
            'databasePassword',
            'rabbitmqErlangCookie',
            'rabbitmqPassword',
            'notificationSmtpPass',
            'databasePasswordConfirmation',
            'failureReason',
            'actualKubeChanges',
            'actualMetadataChanges',
            'actualDatabaseMigrations',
            'actualDeployGroupChanges',
            'expectedDatabaseMigrations',
            'expectedKubeChanges',
            'expectedMetadataChanges',
            'expectedDeployGroupChanges'
        ]
    },
    identity: { role: ['system', 'metaui:useCaseVersion'] }
};
export const clientOnlyKeys = [
    '_idx',
    '_meta',
    '$loki',
    '$dbLoki',
    'downloaded',
    'depreciatedValue',
    'amountDepreciated',
    'inDatabase',
    'scanType',
    'intervalMilliseconds'
];
export const commonKeysToOmit = ['_id', 'tenantId', ...clientOnlyKeys];

const _p = {
    metadata: {
        namespaces: []
    },
    commonKeysToOmit,
    getCurrentSession,
    getLocalStorageKey: localStorage.getKey,
    setLocalStorageKey: localStorage.setKey,
    getRouter: _getRouter
};
export const _private = _p;

export function setMetadata(metadata) {
    _p.metadata = metadata;
}

/**
 * Most of the time not what you want.
 * Use specific methods for e.g. topMenu or Namespaces, below, instead.
 * @param {string} [path] OPTIONAL. Path to subsection in the metadata document
 * @returns Complete raw metadata document
 */
export function getRawMetadata(path) {
    if (path) {
        return get(_p.metadata, path);
    }
    return _p.metadata;
}

export function getTopMenu() {
    // April 2024: new format
    if (_p.metadata?.topMenuV2?.length >= 1) {
        return _p.metadata?.topMenuV2;
    }
    // backwards compatibility for interim approach (using new stacked under old block)
    // which for whatever reason wasn't compatible with old RN versions:
    if (_p.metadata?.topMenu.length > 1) {
        return _p.metadata?.topMenu.filter(hNode => hNode.hNodeType !== 'MeatballProfileLayout');
    }
    // backwards compatibility for original approach
    return _p.metadata?.topMenu;
}

export function getNamespaces() {
    return _p.metadata?.namespaces;
}

export function getUseCaseRelease() {
    return _p.metadata?.release;
}

export function getUseCaseTitle() {
    return _p.metadata?.title;
}

export function getUseCaseId() {
    return _p.metadata?._id;
}

export function getNavMenu() {
    function findNavHeadings(hNode) {
        if (['navHeading', 'page'].includes(hNode.hNodeTypeGroup)) return hNode;
        if (hNode.children) {
            return hNode.children.flatMap(findNavHeadings).filter(x => !!x);
        }
    }
    return findNavHeadings(_p.metadata.hNodes);
}

export function getNamespaceMetadata(namespaceTitle) {
    return _p.metadata.namespaces.find(ns => ns.title === namespaceTitle);
}

export function getRelationMetadata(namespaceTitle, relationTitle, errorOnAbsence = true) {
    let namespace, relation;

    namespace = getNamespaceMetadata(namespaceTitle);
    if (namespace) {
        relation = namespace.relations.find(rel => rel.title === relationTitle);
    }
    if (!relation) {
        if (errorOnAbsence) {
            throw new Error(`Cannot find relation: ${namespaceTitle}_${relationTitle}`);
        } else {
            return;
        }
    }
    return relation;
}

export function getPrettyRelationName(namespaceTitle, relationTitle) {
    let relation = getRelationMetadata(namespaceTitle, relationTitle, false);
    if (!relation) return relationTitle;
    return relation.prettyName || relation.title;
}

/**
 * Return 'title' propertyName alternatives such as assetNo, rmaNo or displayName.
 * Ideally, this would not be needed.  Eventually, we hope to eliminate these alternatives
 * and just use title.
 * @param {String} namespaceTitle
 * @param {String} relationTitle
 */
export function getTitleAlternative(namespaceTitle, relationTitle, fallback = 'title') {
    return get(titleAlternatives, `${namespaceTitle}.${relationTitle}.propertyName`, fallback);
}

export function getTitleAlternativePretty(namespaceTitle, relationTitle, fallback = 'Title') {
    return get(titleAlternatives, `${namespaceTitle}.${relationTitle}.pretty`, fallback);
}

/**
 * This will look at the children of a parent component (like a form) and find a control
 * that is using the propertyName.  It will then return the title for that control.
 * Usually used to get a user friendly title for a propertyName.
 * @param {string} propertyName - the property name that needs to be found in child controls
 * @param {object} hNode - the hNode for the parent control (like a form)
 * @returns
 */
export function getLabelForPropertyName(propertyName, hNode) {
    // this guards against scenarios where we fire an action outside of a form element
    // like we do in FileMiddleware
    if (hNode?.children == null) return startCase(propertyName);

    let child = hNode.children.find(c => c.propertyName === propertyName && !c.foreignRelation && !c.foreignNamespace);
    return (child ? child.title : propertyName) || propertyName;
}

export function getPathToProperty(hNode) {
    let { propertyName, foreignNamespace, foreignRelation, propertyPath = '', propertyOverride } = hNode;
    if (propertyPath && !propertyPath.endsWith('.')) {
        propertyPath = propertyPath + '.';
    }
    if (propertyOverride != null) {
        return `${propertyPath}${propertyOverride}`;
    }
    if (foreignNamespace != null && foreignRelation != null) {
        return `${propertyPath}${foreignNamespace}:${foreignRelation}`;
    }
    return propertyPath + propertyName;
}

/**
 * Return list of keys that need to be skipped when copying
 * @param {String} namespaceTitle
 * @param {String} relationTitle
 */
export function getCopyKeysToOmit(namespaceTitle, relationTitle) {
    const titleAlt = get(titleAlternatives, `${namespaceTitle}.${relationTitle}.propertyName`, 'title');
    return [titleAlt].concat(commonKeysToOmit, get(copyKeysToOmit, `${namespaceTitle}.${relationTitle}`, []));
}

export function omitClientOnlyKeys(record, extraKeysToOmit = []) {
    return omit(record, clientOnlyKeys.concat(extraKeysToOmit));
}

export function containsHNodeType(hNode, hNodeType) {
    return findHNode([hNode], hNodeType) != null;
}

export function findHNode(hNodes, hNodeTypeOrFunc, recursively = true) {
    let filter = hNodeTypeOrFunc;
    // For backward compatibility, if hNodeType is passed in, convert it to a filter function.
    if (typeof hNodeTypeOrFunc !== 'function') {
        filter = c => c.hNodeType === hNodeTypeOrFunc;
    }
    if (!Array.isArray(hNodes)) {
        hNodes = [hNodes];
    }
    for (const hNode of hNodes) {
        if (filter(hNode)) {
            return hNode;
        }
        if (recursively && hNode.children) {
            let found = findHNode(hNode.children, filter);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

/**
 * Find all hNodes (including children), returning a flat array of them in the order that
 * they are found, searching depth first.
 * @param {Array} hNodes - all hNodes to search.  hNodes may contain a 'children' property that is also searcheable
 * @param {Function} filterFunc - a function returning true given an hNode that should be selected.
 * @returns array of all hNodes matching the given filter function and found within the given hNodes array param
 */
export function findAllHNodes(hNodes, filterFunc) {
    let found = [];
    if (hNodes == null) return found;
    if (!Array.isArray(hNodes)) {
        hNodes = [hNodes];
    }
    if (hNodes != null && Array.isArray(hNodes)) {
        for (const hNode of hNodes) {
            if (filterFunc(hNode)) {
                found.push(hNode);
            }
            if (hNode.children) {
                let childrenFound = findAllHNodes(hNode.children, filterFunc);
                if (childrenFound.length > 0) {
                    found = [...found, ...childrenFound];
                }
            }
        }
    }
    return found;
}

/**
 * Get the minimum data that needs to be stored for a foreign key.
 * e.g. _id and title for most records.
 * @param {object} record - foreign key data (typically contains all fields for foreign relation)
 * @param {object} hNode - metadata for component that is using the record
 * @returns object - the minimum data that should be stored for the foreign relation
 */
export function getMinimumForeignKeyFields(record, hNode) {
    let fields = { _id: record._id };
    const [displayProperties, legacyDisplayProperty] = getAllDisplayProperties(hNode);
    // If a dropdown specifies which values should be displayed in the input
    // or menu items, those are typically the minimum data that is needed
    // to recreate the input/menu item display text when the dropdown is
    // rehydrated with the data later.
    if (displayProperties != null && displayProperties.length > 0) {
        displayProperties.forEach(prop => {
            set(fields, prop, get(record, prop));
        });
    } else {
        // Older metadata uses 'displayName', 'assetNo', 'title', etc.
        fields[legacyDisplayProperty] = record[legacyDisplayProperty];
    }
    return fields;
}

/**
 * Use the data dictionary to determine which fields are being consumed
 * from a foreign key when the application consumes it as a property on the
 * primary relation.
 * @example
 *  An item:item primary record might contain a location:building foreign key
 *  and the data dictionary would know all the fields the application could
 *  need from Blockly when consuming the building from the item:
 *  {
 *    _id: '...',
 *    assetNo: 'some asset'
 *    location:location: {
 *      _id: '...',
 *      title: 'some building'
 *      someOtherField: 'used by the application when referencing this foreign key'
 *    }
 *  }
 * @param {object} record contains the data to extract
 * @param {string} primaryNamespace - namespace of the data dictionary primary namespace
 * @param {string} primaryRelation - relation of the data dictionary primary relation
 * @param {string} foreignNamespace - namespace of the foreign key record
 * @param {string} foreignRelation - relation of the foreign key record
 * @returns
 */
export async function getMinimumForeignKeyFieldsFromDictionary(
    record,
    primaryNamespace,
    primaryRelation,
    foreignNamespace,
    foreignRelation
) {
    const dictionary = await getDictionary();
    const subDictionary =
        dictionary[primaryNamespace][primaryRelation]?.[`${foreignNamespace}:${foreignRelation}`] ?? {};
    if (subDictionary == null) {
        throw new Error(
            `A foreign key of ${foreignNamespace}:${foreignRelation} was not found in namespace ${primaryNamespace} relation ${primaryRelation}.`
        );
    }
    let fields = { _id: record._id };
    let requiredFields = Object.keys(subDictionary).filter(k => k !== '_meta');
    if (requiredFields.length > 0) {
        requiredFields.forEach(prop => {
            set(fields, prop, get(record, prop));
        });
    } else {
        const legacyDisplayProperty = getTitleAlternative(foreignNamespace, foreignRelation, 'title');
        // Older metadata uses 'displayName', 'assetNo', 'title', etc.
        fields[legacyDisplayProperty] = record[legacyDisplayProperty];
    }
    return fields;
}

export function getAllDisplayProperties(hNode) {
    const { propertyName, inputDisplayProperties = [], dropdownDisplayProperties = [] } = hNode;
    const foreignNamespace = hNode.foreignNamespace || hNode.namespace;
    const foreignRelation = hNode.foreignRelation || hNode.relation;
    const displayProperties = uniq([...inputDisplayProperties, ...dropdownDisplayProperties]);
    const nestedProperties = displayProperties.filter(propertyName => propertyName.includes(':'));
    nestedProperties.forEach(propertyName => {
        // extract the namespace, relation and propertyName from the string
        let relation,
            subPropertyNames = [];
        const [namespace, remainder] = propertyName.split(':');
        if (remainder.includes('.')) {
            [relation, ...subPropertyNames] = remainder.split('.');
        } else {
            throw new Error(
                `When registering foreign key for data dictionary, a foreignKey property of ${propertyName} was specified. This includes a namespace and relation, but no actual property.`
            );
        }
        if (subPropertyNames.length > 1) {
            throw new Error(
                `When registering foreign key for data dictionary, a foreignKey property of ${propertyName} was specified. This includes a deeply nested property which is not currently supported.`
            );
        }
        displayProperties.push(`${namespace}:${relation}._id`);
    });
    const legacyDisplayProperty = propertyName || getTitleAlternative(foreignNamespace, foreignRelation, 'title');
    return [displayProperties, legacyDisplayProperty];
}

export function overrideToDirty(record) {
    set(record, 'meta.overriddenToDirty', true);
}

export async function getOfflineRelations() {
    const session = await _p.getCurrentSession();
    // The authorizeOAuthUser.js module results in a slightly different session object, so
    // this compensates.
    // This problem should go away when we switch to Auth0.
    let roleTitle = get(session, 'role.title');
    roleTitle = roleTitle ?? get(session, 'identity:role.title', 'USER');
    // I think the idea here is that if the role is not one of the default roles (i.e. not
    // in constants.WELL_KNOWN_ROLES), then we default to 'ADMIN' so that we can still get
    // the relations ADMIN has access to (during sync).
    let _accessRole = roleTitle;
    if (!Object.keys(constants.WELL_KNOWN_ROLES).includes(roleTitle)) {
        _accessRole = 'ADMIN';
    }
    // The authorizeOAuthUser.js module results in a slightly different session object, so
    // this compensates.
    // This problem should go away when we switch to Auth0.
    let activeUseCaseId = get(session, 'appId');
    activeUseCaseId = activeUseCaseId ?? get(session, 'activeUseCaseId');
    return getNamespaces()
        .flatMap(ns => {
            return ns.relations
                .map(rel => {
                    const methodAccess = rel.methodAccess.find(ma => ma.role === _accessRole) || { get: false };
                    if (!isLocalOnly(ns, rel, activeUseCaseId) && !rel.originalRelation && methodAccess.get) {
                        return {
                            namespace: ns,
                            relation: rel
                        };
                    }
                })
                .filter(x => x);
        })
        .filter(x => x);
}

export const isLocalOnly = (namespace, relation, useCaseId) => {
    // TODO: 2ROOT This probably should be more advanced/dynamic

    // security (:profile) should never be synced
    // transaction:* are create-only objects,
    // which are translated into a bunch of operations elsewhere (currently server side)
    // So we never need to sync those records TO the client.
    // TODO: 2ROOT: implement CICO transaction:* logic client side
    if (['security', 'transaction', 'sensor'].includes(namespace.title)) return true;

    // Only used for events on the client side.
    if (['application'].includes(namespace.title)) return !['savedFilter', 'sharedFilter'].includes(relation.title);

    //dataCollection is only for devices. UI should never access anything in there.
    if (['dataCollection'].includes(namespace.title)) return true;

    // There is no reason to ever pull "all" swagger.
    if (['swagger', 'dataDictionary'].includes(relation.title)) return true;

    // Only Nucleus needs all License agreements.
    if (['eula', 'mla'].includes(relation.title) && useCaseId !== useCaseIds.NUCLEUS) return true;

    return false;
};

let dictionary;
export async function setDictionary(_dictionary) {
    dictionary = _dictionary;
    await _p.setLocalStorageKey('dataDictionary', dictionary, undefined, false);
}

// Should be populated by loadUseCase.js
export async function getDictionary() {
    if (_p.getRouter().isPreUseCaseRoute()) {
        return;
    }
    // Lazy load this from storage
    if (dictionary == null) {
        dictionary = await _p.getLocalStorageKey('dataDictionary', undefined, undefined, false);
    }
    if (dictionary == null) {
        throw new Error('No data dictionary was found to validate the model.');
    }
    return dictionary;
}

export function getDuplicateCheckFields(namespace, relation) {
    return get(duplicateCheckFields, `${namespace}.${relation}`, 'title');
}
