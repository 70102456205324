//Call_[npm_run_updateIndexes]_to_update_this_file.

// @create-index

import _didBulk_namespace_relation_remove from './didBulk_namespace_relation_remove';
import _doingBulk_namespace_relation_remove from './doingBulk_namespace_relation_remove';
import * as _utilities from './utilities';
import _willBulk_namespace_relation from './willBulk_namespace_relation';
import _willBulk_namespace_relation_remove from './willBulk_namespace_relation_remove';
export const didBulk_namespace_relation_remove = _didBulk_namespace_relation_remove;
export const doingBulk_namespace_relation_remove = _doingBulk_namespace_relation_remove;
export const utilities = _utilities;
export const willBulk_namespace_relation = _willBulk_namespace_relation;
export const willBulk_namespace_relation_remove = _willBulk_namespace_relation_remove;
export default {didBulk_namespace_relation_remove, doingBulk_namespace_relation_remove, utilities, willBulk_namespace_relation, willBulk_namespace_relation_remove};

