import defaultWillRemove from '../../namespace/relation/willRemove_namespace_relation';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';
import memoizedAccessChecker from '../../../../utilities/accessChecker';
import { getCriteriaKeys, checkAccessForEachCriteria } from '../../../../utilities/inventoryAccess';

export default {
    verb: 'willRemove',
    namespace: 'inventory',
    relation: 'inventory',
    prerequisites: [
        {
            context: { verb: 'get', namespace: 'identity', relation: 'userRole' }
        }
    ],
    priority: defaultWillRemove.priority + 10, //higher priority than (run before) default
    description:
        'Prevent the deletion if the inventory is in a busy state or if the user does not have access to all the locations in the inventory',
    //this is the actual logic:
    logic: willRemove
};

const _p = {
    memoizedAccessChecker,
    checkAccessForEachCriteria
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{record:{title:string, 'inventory:status':{title:string}}}} RemovePayload
 *
 * @param {{
 *   data: RemovePayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @throws {Error} if the inventory is not in a closed (or errored) state
 * @returns {RemovePayload}
 */
async function willRemove({ data, prerequisiteResults, context, dispatch }) {
    const { namespace, relation } = context;
    //if the data already has a meta object on the root, we don't need to do anything, just pass through
    //most likely came from sockets
    if (data.skipConfirm) return data;

    const { title = '', 'inventory:status': status } = data.record;

    const userRole = prerequisiteResults?.[0]?.result?.[0];
    const clientDataRights = getAllFeatureFlags(context).includes('clientDataRights') ?? false;
    const dataAccess = await _p.memoizedAccessChecker(namespace, relation, userRole, clientDataRights);

    // Check that the user has permission to delete the inventory by
    // checking that the user has access to all the locations in the inventory
    const criteriaKeys = getCriteriaKeys(data.record);
    for (const criteria of criteriaKeys) {
        await _p.checkAccessForEachCriteria(data.record, criteria, dataAccess, message =>
            showAndThrowError(dispatch, message)
        );
    }

    if (!['Closed', 'Error'].includes(status?.title)) {
        const message = `Unable to delete inventory ${title}. It currently has status ${
            status?.title || 'being created'
        }. Please make sure the inventory is closed before deleting.`;
        showAndThrowError(dispatch, message);
    }

    return data;
}

function showAndThrowError(dispatch, message) {
    dispatch(
        {
            addToList: false,
            isError: true,
            message
        },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
    throw new Error(message);
}
