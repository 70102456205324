import cloneDeep from 'lodash/cloneDeep';
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import { constants } from 'lib_ui-services';

const _p = {
    setDefaultValuesIfNecessary
};

export const _private = _p;

export default {
    verb: 'didChange',
    namespace: 'transaction',
    description: 'look up current stock',
    useCaseIds: [constants.useCaseIds.REDBEAM_INVENTORY_TRACKING],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'stock',
                type: 'find'
            },
            query: async ({ data, context }) => {
                const { newRecord: _newRecord, hNode } = data;
                const newRecord = cloneDeep(_newRecord);
                // Need to merge in defaults so user doesn't have to re-select location
                await _p.setDefaultValuesIfNecessary({...context, isNew: true, hNode}, newRecord);

                let { 'item:item': item, [context.relation]: { 'location:location': location } = {} } = newRecord;
                if (!location) {
                    location = newRecord['location:location'];
                }

                if (!item) {
                    throw new Error('Item not selected');
                }
                if (!location) {
                    throw new Error('Location not selected');
                }

                return {
                    'item:item._id': item._id,
                    'location:location._id': location._id,
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: didChange
};

async function didChange({ data, prerequisiteResults }) {
    const [{ result: [existingStock] = [] }] = prerequisiteResults;

    const quantity = existingStock?.quantity || 0;

    return {
        ...data,
        newRecord: {
            ... data.newRecord,
            currentInventoryFrom: quantity.toString(),
            currentInventoryTo: quantity.toString()
        },
        oldRecord: {
            ... data.oldRecord,
            currentInventoryFrom: quantity.toString(),
            currentInventoryTo: quantity.toString()
        }
    };
}