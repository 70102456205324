import lodash from 'lodash';
const { set } = lodash;
const { unset } = lodash;
const { cloneDeep } = lodash;
const { isEqual } = lodash;
import notHandledHere from './notHandledHere';
import { metadata } from 'lib_ui-services';

export default {
    verb: 'doingChange',
    description: 'Merge changes into the given namespace/relation record.',
    excludedNamespaceRelations: notHandledHere,
    //this is the actual logic:
    logic: change
};

function change({ data }) {
    const newRecord = cloneDeep(data.newRecord);
    const {
        propertiesToUnset: _propertiesToUnset = [],
        propertyPath = '',
        foreignNamespace,
        foreignRelation,
        propertyName,
        newValue,
        hNode
    } = data;
    const path = metadata.getPathToProperty(data);

    // remove unset properties if the user has requested a new value for them.
    const propertiesToUnset = _propertiesToUnset.filter(
        key => !isEqual({ propertyPath, foreignNamespace, foreignRelation, propertyName }, key)
    );
    // setting to the empty string is considered further down as unsetting the property
    // make sure to record its path now that we have the opportunity
    if (newValue === undefined || newValue === '') {
        propertiesToUnset.push({ propertyPath, foreignNamespace, foreignRelation, propertyName });
        unset(newRecord, path);
    } else {
        set(newRecord, path, newValue);
    }
    return { newRecord, propertiesToUnset, hNode };
}
