import getFilterFromFactory from '../filterFactory';

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    getMql
};

function fromHNode(hNode, contextualInfo, filterExtractionCallback) {
    let { propertyName, propertyPath, children: _children } = hNode;
    if (propertyPath) {
        propertyPath = `${propertyPath}.${propertyName}`;
    } else {
        propertyPath = propertyName;
    }

    const children = filterExtractionCallback(_children, contextualInfo, filterExtractionCallback);
    return getFilter(propertyPath, children);
}

function getFilter(propertyPath, children) {
    return {
        propertyPath,
        children
    };
}

function getUriComponent() {
    throw new Error('not implemented');
}

function getMql(filters) {
    if (filters.nestedListEntry == null) return;
    const { propertyPath, children = {} } = filters.nestedListEntry;

    const childFilters = Object.keys(children).reduce((result, filterName) => {
        // Passing in `filterFactory` as I can't overcome circular dependencies in a different way... :(
        //using an async import requires turning ALL getMql async, which has significant impact everywhere.
        let filter = getFilterFromFactory(filterName)?.getMql?.(children);
        if (filter != null) {
            if (filter.$and) {
                filter = filter.$and.reduce((x, y) => {
                    return { ...x, ...y };
                }, {});
            }
            return {
                ...result,
                ...filter
            };
        } else {
            return result;
        }
    }, {});
    if (!Object.keys(childFilters).length) return;
    return { [propertyPath]: { $elemMatch: childFilters } };
}
