import { errors } from 'lib_ui-primitives';
import genericRule from '../namespace/relation/doingValidate_namespace_relation';

export default {
    ...genericRule,
    excludedNamespaceRelations: [],
    namespace: 'print',
    relation: 'job',
    priority: genericRule.priority + 10, // before the generic rule
    description: 'Prevent updating standard/system roles',
    logic: doingValidate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */

function doingValidate(message) {
    const { 'print:printer': printer, 'print:template': template } = message.data.newRecord;
    let fieldErrors = {};
    let hasErrors = false;
    if (!printer) {
        const errorMessage = 'Printer is required.';
        fieldErrors['print:printer'] = errorMessage;
        hasErrors = true;
    }
    if (!template) {
        const errorMessage = 'Template is required.';
        fieldErrors['print:template'] = errorMessage;
        hasErrors = true;
    }
    if (hasErrors) {
        let message = 'There are problems with this Print Job.  For details, see the red message(s) above.';
        throw new errors.ValidationError(message, fieldErrors);
    }
    return message;
}
