export default {
    verb: 'willCreate',
    namespace: 'transaction',
    relation: 'transfer',
    description: 'proactively adjust the applicable stock levels',
    //this is the actual logic:
    logic: willCreate
};

// eslint-disable-next-line no-unused-vars
async function willCreate({ data, dispatch, context }) {
    // #7781
}
