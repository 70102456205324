import { createElement as rc } from 'react';
import PropTypes from 'prop-types';
import _MultiPickerDropDown from '../_abstractComponent/MultiPickerDropDown';
import { View, styled, fromTheme, h3 } from 'lib_ui-primitives';
import useFormControl from '../../hooks/useFormControl';

const Container = styled(View).attrs({ name: 'multipicker-dropdown' })`
    flex-direction: column;
    background-color: ${fromTheme('backgroundColor')};
    padding-left: ${fromTheme('viewMargin')};
    max-width: ${({ theme }) => theme.form.entryMaxWidth - theme.viewMargin}px;
`;
Container.displayName = 'Container';

const TagContainer = styled(View).attrs({ name: 'tag-container' })`
    flex-wrap: wrap;
    padding-bottom: ${fromTheme('viewPadding')};
`;
TagContainer.displayName = 'TagContainer';

const EMPTY_ARRAY = [];
const _p = {
    useFormControl
};
export const _private = _p;
/**
 * Allow selection of an array of ShortText values
 */
function MultiPickerDropDown(props) {
    const {
        title,
        value = EMPTY_ARRAY,
        setValue,
        disabled,
        defaultReady,
        errors,
        onBlur,
        active,
        autoFocus,
        scanButton,
        onFocus
    } = _p.useFormControl(props);

    if (!defaultReady) {
        return rc(h3, null, 'Loading...');
    }
    // prettier-ignore
    return rc(_MultiPickerDropDown,{...props, errors, title, value, setValue, disabled, autoFocus, scanButton, onFocus, onBlur, active});
}

MultiPickerDropDown.propTypes = {
    hNode: PropTypes.shape({
        treePosition: PropTypes.shape({
            sequence: PropTypes.number.isRequired
        }).isRequired
    }).isRequired
};
export default MultiPickerDropDown;
