import { createElement as rc, useCallback, useMemo, useState } from 'react';
import { styled, Chip, View, fromTheme, hooks } from 'lib_ui-primitives';
import { default as _DropDown } from '../_abstractComponent/DropDown';
import useActiveComponentAwareness from '../../hooks/useActiveComponentAwareness';
import lodash from 'lodash';
const { get } = lodash;
const { isEqual } = lodash;
import useEventSink from '../../hooks/useEventSink';
import useHasFeatureFlag from '../../hooks/useHasFeatureFlag';

const { useTimeout } = hooks;

const ChipContainer = styled(Chip.Container).attrs({ name: 'chip-container' })`
    min-width: 150px;
`;

const _MultiPickerDropDown = styled(View).attrs({ name: 'multi-picker-dropdown' })`
    min-width: 150px;

    margin-right: ${fromTheme('textMargin')};
    flex-direction: column;
    flex-shrink: 0;
`;
_MultiPickerDropDown.displayName = 'MultiPickerDropDown';

const _EmbeddedDropDown = styled(_DropDown).attrs({ name: 'drop-down-search' })`
    padding: 0;
    min-width: 150px;
`;

const _p = {
    _EmbeddedDropDown,
    useHasFeatureFlag
};
export const _private = _p;

const defaultSort = [
    {
        id: '5a624d17c1885033a496b31f',
        hNodeType: 'OrderBy',
        hNodeTypeGroup: 'filter',
        propertyName: 'title',
        direction: 'ascending'
    }
];

const emptyArray = [];

/**
 * @typedef {Object} Props
 * @property {Object} Props.hNode
 * @property {string} Props.currentRoute
 */
/** @type {import('react').FC<Props>} */
function MultiPickerDropDown(props) {
    const {
        hNode,
        hNode: {
            title,
            children = defaultSort,
            propertyName = 'title',
            dropdownDisplayProperties = emptyArray,
            foreignNamespace,
            foreignRelation
        },
        value: values = emptyArray,
        setValue
    } = props || { hNode: {} };

    //generic stuff
    const clientDataRights = _p.useHasFeatureFlag('clientDataRights');
    const [, publish] = useEventSink();
    const { active, onBlur, onFocus } = useActiveComponentAwareness();
    const [dropdownValue, setDropdownValue] = useState();
    // react guarantees setDropdownValue is the same ref.
    // eslint-disable-next-line
    const delayClearDropdown = useTimeout(setDropdownValue, []);

    // The metadata defines how the input text should be formatted
    const itemToString = useCallback(
        item => getItemValuesText(item, propertyName, dropdownDisplayProperties),
        [propertyName, dropdownDisplayProperties]
    );

    const aggregateAndSetValue = useCallback(
        _selectedValue => {
            if (!_selectedValue) {
                // The user selected the "Unassigned" row, which is passed on as `undefined`.
                _selectedValue = {
                    _id: '0'.repeat(24),
                    [propertyName || dropdownDisplayProperties?.[0] || 'title']: 'Unassigned'
                };
            }
            //call setDropdownValue explicitly, before clearDropdown to make sure there is a change so it actually clears the dropdown
            setDropdownValue(_selectedValue);

            if (!values.find(x => x._id === _selectedValue._id)) {
                // Do not mutate original selection object.
                let addedValue = { _id: _selectedValue._id };

                //if this wasn't a regex (starts  with) search
                if (_selectedValue._id !== 'hint-regex') {
                    // The metadata defines how the list entry text should be formatted.
                    //Overriding the propertyName should be fine as the _id is used in the filter
                    //unless of course the display properties IS the property name
                    if (!isEqual(dropdownDisplayProperties, [propertyName])) {
                        addedValue[propertyName] = itemToString(_selectedValue);
                    } else {
                        addedValue[propertyName] = _selectedValue[propertyName];
                    }
                } else {
                    addedValue = { ..._selectedValue };
                }

                let newValue;
                if (_selectedValue?.isDefaultRecord) {
                    newValue = values;
                } else {
                    newValue = [...values, addedValue];
                }
                setValue(newValue);

                publish(
                    { value: newValue },
                    {
                        verb: 'select',
                        namespace: foreignNamespace,
                        relation: foreignRelation,
                        status: 'success'
                    }
                );
            }
            delayClearDropdown();
        },
        [
            values,
            delayClearDropdown,
            setValue,
            publish,
            foreignNamespace,
            foreignRelation,
            dropdownDisplayProperties,
            propertyName,
            itemToString
        ]
    );

    const onRemove = useCallback(
        value => {
            if (values.find(x => x._id === value._id)) {
                const newValues = values.filter(x => x._id !== value._id);
                setValue(newValues);
                publish(
                    { value: newValues },
                    {
                        verb: 'select',
                        namespace: foreignNamespace,
                        relation: foreignRelation,
                        status: 'success'
                    }
                );
            }
        },
        [setValue, values, publish, foreignNamespace, foreignRelation]
    );

    // `displayUnassignedRow` is used in the abstract dropdown to (only) display the "Unassigned" row
    // in the dropdown when another selection is made/present.
    // in the case of multiPicker Dropdown, there never IS such a selection.
    // Therefore, we use the `alwaysDisplayUnassignedRow` property to _always_ show the "Unassigned" row.,
    // so that a user can select the option of "Unassigned" as value, e.g. in the case of data rights
    // to indicate they want access to records without any location.
    const memoizedHNode = useMemo(
        () => ({ ...hNode, alwaysDisplayUnassignedRow: hNode.displayUnassignedRow ?? false, children }),
        [hNode, children]
    );
    // keep the following around until clientDataRights is globally enabled:
    const oldMemoizedHNode = useMemo(() => ({ ...hNode, displayUnassignedRow: false, children }), [hNode, children]);
    // prettier-ignore
    return rc(_MultiPickerDropDown, null,
        rc(_p._EmbeddedDropDown, {
            ...props, active, onBlur, onFocus,
            hNode: clientDataRights ? memoizedHNode : oldMemoizedHNode,
            value: dropdownValue,
            setValue: aggregateAndSetValue,
            selectedValues: values,
            title,
            supportWildCard: true
        }),
        rc(
            ChipContainer,
            null,
            values.map((value, i) => rc(Chip, { key: `chip${i}`, value: itemToString(value), onClick: () => onRemove(value) }))
        )
    );
}

export default MultiPickerDropDown;

/**
 * Concatenates the value for propertyName and the additional display properties
 * @param {Object} item
 * @param {string} [propertyName="title"]
 * @param {string[]} [displayProperties=[]]
 * @returns {string} string
 */
function getItemValuesText(item, propertyName = 'title', displayProperties = []) {
    if (!displayProperties.length) {
        return get(item, propertyName, item?.title ?? '');
    }

    const displayValues = displayProperties.map(dp => get(item, dp, ''));
    const existingDisplayValues = displayValues.filter(dv => !!dv);

    if (!existingDisplayValues.length) {
        return get(item, propertyName, item?.title ?? '');
    }

    return existingDisplayValues.join(', ');
}
