import { helpers } from '@sstdev/lib_metadata-config';
import { metadata } from 'lib_ui-services';

export default function getFormElementHNodes(children) {
    if (!children) return []; // unlikely except in test environments.
    let formElements = children.filter(c => c.hNodeTypeGroup === 'formElement' && c.hNodeType !== 'NestedObject');
    // It isn't yet necessary to set default values for elements nested inside an array.
    // If this becomes necessary, setting the default value will need to occur inside this
    // recursive method (instead of this method flattening the formElements out and setting the
    // default values on the flattened array).
    // Also the 'required/not required' will need to be evaluated at each level of the recursion
    // preventing the recursion from continuing if the parent formElement is not required.
    const formElementContainers = children.filter(
        hNode => hNode.children && helpers.getJavaScriptTypeForHNodeType(hNode) !== 'Array'
    );
    formElementContainers.forEach(container => {
        // flatten all elements into one array.
        if (container.hNodeType === 'NestedObject') {
            const parentPath = metadata.getPathToProperty(container);
            formElements = [
                ...formElements,
                ...getFormElementHNodes(
                    container.children.map(({ propertyPath = '', ...child }) => {
                        return {
                            ...child,
                            propertyPath: propertyPath ? `${parentPath}.${propertyPath}` : parentPath
                        };
                    })
                )
            ];
        } else {
            formElements = [
                ...formElements,
                ...getFormElementHNodes(container.children) // recurse
            ];
        }
    });
    return formElements;
}
