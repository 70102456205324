import { constants } from 'lib_ui-services';
import basicWillExport from '../../namespace/relation/export_namespace_relation/willExport_namespace_relation';

// define this here to keep any reference to AMI008 contained to this one file.
const AMI008 = {
    _id: '6',
    title: 'AMI008',
    contentType: 'text/csv',
    description: 'PALM Inbound Asset Physical Inventory CSV File (AMI008)',
    fileExt: '.csv',
    bookType: 'csv'
};

export default {
    verb: 'willExport',
    namespace: 'item',
    relation: 'item',
    description: 'Intercept export definition to tweak for AMI008',
    // we want this to run AFTER the default willExport, letting it take care
    // of communicating with the user, etc
    priority: (basicWillExport.priority || 0) - 5,
    useCaseIds: [constants.useCaseIds.PALM],
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                if (data?.filePrefix?.toUpperCase() !== AMI008.title) {
                    //avoid lookup, as lookup will happen later. Throw error to abort prerequisite workflow
                    // (does not abort entire workflow!)
                    throw new Error('Request is not for AMI008');
                }
                return {
                    'meta.deleted': { $exists: false }
                };
            }
        }
    ],
    logic: willExport
};

async function willExport({ data, prerequisiteResults, ...rest }) {
    if (data?.filePrefix?.toUpperCase() !== AMI008.title) {
        return data;
    }

    const prerequisiteResult = prerequisiteResults[0];
    const result = prerequisiteResult?.result ?? [];
    const records = result.map(x => {
        if (x.inventory?.[0]?.isNew) {
            x.reviewSW = 'O'; // "Over"
        } else if (x.inventory?.[0]?.found) {
            x.reviewSW = 'I'; // "Inventory"
        } else {
            x.reviewSW = 'U'; // "Under"
        }
        return x;
    });

    // set the file type definition, as well as stuff in properly prepared data
    // if we don't set it a file type, it will export to .xlsx by default, which we don't want.
    const newData = {
        ...data,
        filePrefix: AMI008.title,
        fileType: AMI008,
        data: records
    };

    return { data: newData, ...rest };
}
